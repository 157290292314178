import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'
import PoliticalEdCard from '../../components/PoliticalEdCard/PoliticalEdCard'

const History = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  return (
    <Layout title={pageData.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={pageData.headerImage.photo} alt={pageData.headerImage.altText}>{pageData.heading}</Header>
      <div className="flex-row political-ed-row">
        {pageData.cards.map(card => (
          <PoliticalEdCard info={card} key={card.title} />
        )).reverse()}
      </div>
    </Layout>
  )
}

export default History

export const pageQuery = graphql`query HistoryQuery {
  markdownRemark(frontmatter: {title: {eq: "History"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      cards {
        title
        link
        video
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
        report {
          publicURL
        }
        description
      }
    }
  }
}`