import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import * as styles from './PoliticalEdCard.module.css'

const PoliticalEdCard = (props) => {
  let media = ''
  let title = <h2>{props.info.title}</h2>

  const image = getImage(props.info.photo)

  if (props.info.video) {
    if (props.info.video.includes('youtube')) {
      media = (
        <div className="video-container">
          <iframe src={props.info.video} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      )
    } else {
      media = (
        <video src={props.info.video} controls />
      )
    }
  } else if (props.info.photo) {
    if (props.info.link && !props.info.report?.publicURL) {
      media = <a href={props.info.link}><GatsbyImage image={image} objectFit="scale-down" alt={`Thumbnail for ${props.info.title}.`} /></a>
      title = <h2><a href={props.info.link}>{props.info.title}</a></h2>
    } else if (props.info.report.publicURL) {
      media = <a href={props.info.report.publicURL}><GatsbyImage image={image} objectFit="scale-down" alt={`Thumbnail for ${props.info.title}.`} /></a>
      title = <h2><a href={props.info.report.publicURL}>{props.info.title}</a></h2>
    } else {
      media = <GatsbyImage image={image} objectFit="scale-down" alt="" />
    }
  }

  return (
    <div className={styles.political_ed_card}>
      {media}
      {title}
      {props.info.description ? <ReactMarkdown children={props.info.description} /> : null}
    </div >
  )
}

export default PoliticalEdCard